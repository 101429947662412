import Papa from "papaparse";
import { currencyFormatExcel } from "../../../services/utils";
import api from "../../../services/api";

export default async function exportData(filter, teamName, visibility) {
  let data = [];

  let getDataApi = api.getBudgetData;

  if (filter.groupBy) {
    getDataApi = api.getBudgetGroupBy;
  }

  let budget = await getDataApi(filter, {
    curPage: 1,
    pageSize: 999999,
  });

  const capitalize = function (str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  for (let e of budget.data.rows) {
    let head = {};
    if (filter.groupBy) {
      if (filter.groupBy === "Team") {
        head = { Team: e.teamName };
      } else if (filter.groupBy === "Department") {
        head = { Department: e.departmentName };
      }
    } else {
      head = { "HR Code": e.employee.hrCode, "First Name": e.employee.firstName, "Last Name": e.employee.lastName };
    }

    data.push({
      ...head,
      Type: "",
      Value: "",
      Jan: "",
      Feb: "",
      Mar: "",
      Apr: "",
      May: "",
      Jun: "",
      Jul: "",
      Aug: "",
      Sep: "",
      Oct: "",
      Nov: "",
      Dec: "",
      "13th": "",
      Total: "",
    });

    for (let attr in visibility) {
      if (visibility[attr])
        data.push({
          Type: attr.endsWith("Actual") ? "Actual" : "Plan",
          Value: capitalize(attr.replace("Actual", "").replace("Plan", "")),
          Jan: currencyFormatExcel(e.months[0][attr + "_USD"]),
          Feb: currencyFormatExcel(e.months[1][attr + "_USD"]),
          Mar: currencyFormatExcel(e.months[2][attr + "_USD"]),
          Apr: currencyFormatExcel(e.months[3][attr + "_USD"]),
          May: currencyFormatExcel(e.months[4][attr + "_USD"]),
          Jun: currencyFormatExcel(e.months[5][attr + "_USD"]),
          Jul: currencyFormatExcel(e.months[6][attr + "_USD"]),
          Aug: currencyFormatExcel(e.months[7][attr + "_USD"]),
          Sep: currencyFormatExcel(e.months[8][attr + "_USD"]),
          Oct: currencyFormatExcel(e.months[9][attr + "_USD"]),
          Nov: currencyFormatExcel(e.months[10][attr + "_USD"]),
          Dec: currencyFormatExcel(e.months[11][attr + "_USD"]),
          "13th": currencyFormatExcel(e.months[12][attr + "_USD"]),
          Total: currencyFormatExcel(e.months[13][attr + "_USD"]),
        });
    }
  }

  let total = getTotal(budget.data.rows);

  let head;
  if (filter.groupBy) {
    if (filter.groupBy === "Team") {
      head = { Team: "TOTAL" };
    } else if (filter.groupBy === "Department") {
      head = { Department: "TOTAL" };
    }
  } else {
    head = { "First Name": "TOTAL" };
  }

  data.push(head);

  for (let attr in visibility) {
    if (visibility[attr])
      data.push({
        Type: attr.endsWith("Actual") ? "Actual" : "Plan",
        Value: capitalize(attr.replace("Actual", "").replace("Plan", "")),
        Jan: currencyFormatExcel(total[0][attr + "_USD"]),
        Feb: currencyFormatExcel(total[1][attr + "_USD"]),
        Mar: currencyFormatExcel(total[2][attr + "_USD"]),
        Apr: currencyFormatExcel(total[3][attr + "_USD"]),
        May: currencyFormatExcel(total[4][attr + "_USD"]),
        Jun: currencyFormatExcel(total[5][attr + "_USD"]),
        Jul: currencyFormatExcel(total[6][attr + "_USD"]),
        Aug: currencyFormatExcel(total[7][attr + "_USD"]),
        Sep: currencyFormatExcel(total[8][attr + "_USD"]),
        Oct: currencyFormatExcel(total[9][attr + "_USD"]),
        Nov: currencyFormatExcel(total[10][attr + "_USD"]),
        Dec: currencyFormatExcel(total[11][attr + "_USD"]),
        "13th": currencyFormatExcel(total[12][attr + "_USD"]),
        Total: currencyFormatExcel(total[13][attr + "_USD"]),
      });
  }
  var csv = Papa.unparse(data, { quotes: true });
  downloadCsv(csv, `Export ${teamName || "All"} - ${filter.year} - USD`);
}

const getTotal = (budget) => {
  let total = [];
  for (let employee of budget) {
    for (let month in employee.months) {
      if (employee.months[month]) {
        if (!total[month]) {
          total[month] = {
            bonusActual: 0,
            bonusActual_USD: 0,
            bonusPlan: 0,
            bonusPlan_USD: 0,
            currencyCode: 0,
            otherActual: 0,
            otherActualMap: {},
            otherActualMap_USD: {},
            otherActual_USD: 0,
            otherPlan: 0,
            otherPlan_USD: 0,
            salaryActual: 0,
            salaryActual_USD: 0,
            salaryPlan: 0,
            salaryPlan_USD: 0,
            totalActual: 0,
            totalActual_USD: 0,
            totalPlan: 0,
            totalPlan_USD: 0,
          };
        }

        if (month === 13) {
          total[month].bonusActual += employee.months[month].bonusActual;
          total[month].bonusPlan += employee.months[month].bonusPlan;
          total[month].otherActual += employee.months[month].otherActual;
          total[month].otherPlan += employee.months[month].otherPlan;
          total[month].salaryActual += employee.months[month].salaryActual;
          total[month].salaryPlan += employee.months[month].salaryPlan;
          total[month].totalActual += employee.months[month].totalActual;
          total[month].totalPlan += employee.months[month].totalPlan;
        } else {
          total[month].bonusActual_USD += employee.months[month].bonusActual_USD || 0;
          total[month].bonusPlan_USD += employee.months[month].bonusPlan_USD || 0;
          total[month].otherActual_USD += employee.months[month].otherActual_USD || 0;
          total[month].otherPlan_USD += employee.months[month].otherPlan_USD || 0;
          total[month].salaryActual_USD += employee.months[month].salaryActual_USD || 0;
          total[month].salaryPlan_USD += employee.months[month].salaryPlan_USD || 0;
          total[month].totalActual_USD += employee.months[month].totalActual_USD || 0;
          total[month].totalPlan_USD += employee.months[month].totalPlan_USD || 0;

          total[month].bonusActual = total[month].bonusActual_USD;
          total[month].bonusPlan = total[month].bonusPlan_USD;
          total[month].currencyCode = total[month].currencyCode_USD;
          total[month].otherActual = total[month].otherActual_USD;
          total[month].otherPlan = total[month].otherPlan_USD;
          total[month].salaryActual = total[month].salaryActual_USD;
          total[month].salaryPlan = total[month].salaryPlan_USD;
          total[month].totalActual = total[month].totalActual_USD;
          total[month].totalPlan = total[month].totalPlan_USD;
        }
      }
    }
  }

  if (!total[13]) total[13] = {};
  return total;
};

const downloadCsv = (data, fileName) => {
  const csvData = new Blob([data], { type: "text/csv" });
  const csvURL = URL.createObjectURL(csvData);
  const link = document.createElement("a");
  link.href = csvURL;
  link.download = `${fileName}.csv`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
