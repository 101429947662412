import { NavLink } from "react-router-dom";
import logo from "../images/logo_main.svg";
import moment from "moment";

// Context
import { useAuth } from "../context/useAuth";

export default function Sidebar() {
  const auth = useAuth();

  return (
    <div className="sidebar">
      <div className="sidebar-logo">
        <NavLink to="/">
          <img className="sidebar-logo-img" src={logo} alt="Logo"></img>
        </NavLink>
      </div>

      <div className="sidebar-menu">
        <NavLink className="sidebar-btn" to={"/employee"}>
          <i className="material-icons">people</i>
          Personnel
        </NavLink>

        <NavLink className="sidebar-btn" to={"/budget/departments/" + moment().format("YYYY")}>
          <i className="material-icons">attach_money</i>
          Budgets
        </NavLink>

        {auth.user && auth.user.role === "admin" && (
          <NavLink className="sidebar-btn" to={"/importHistory"}>
            <i className="material-icons">history</i>
            Import History
          </NavLink>
        )}

        {auth.user && auth.user.role === "admin" && (
          <NavLink className="sidebar-btn" to={"/user"}>
            <i className="material-icons">person</i>
            Users
          </NavLink>
        )}
      </div>
      <div className="sidebar-bottom">
        <NavLink className="sidebar-btn not-active" to={"/"} onClick={auth.logout}>
          <i className="material-icons">logout</i>
          Log Out
        </NavLink>
      </div>
    </div>
  );
}
