import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { currencyFormatExcelNum } from "../../../services/utils";
import api from "../../../services/api";

export default async function exportData(filter, teamName, optionExport) {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Report", { views: [{ showGridLines: false }] });

  worksheet.properties.outlineProperties = {
    summaryBelow: false,
    summaryRight: false,
  };

  let getDataApi = api.getBudgetData;

  //   if (filter.groupBy) {
  //     getDataApi = api.getBudgetGroupBy;
  //   }

  let budget = await getDataApi(
    { ...filter, productFocus: true },
    {
      curPage: 1,
      pageSize: 999999,
    }
  );

  let segments = budget.data.segments;

  let numFmt = "#,##0.00";
  let numFmtPerc = "#%";
  let format = numFmt;
  if (optionExport.values === "percentage") {
    format = numFmtPerc;
  }

  let columns = [
    { header: "Division", key: "Division" },
    { header: "Department", key: "Department" },
    { header: "Team", key: "Team" },
    { header: "EE No.", key: "EE No." },
    { header: "First Name", key: "First Name" },
    { header: "Last Name", key: "Last Name" },
  ];

  if (optionExport.listByMonth) {
    columns.push({ header: "Month", key: "month" });
  }

  for (let s of segments) {
    let col = { header: s.name, key: s.productSegmentId };

    col.style = {
      numFmt: format,
    };

    columns.push(col);
  }
  columns.push({
    header: "Not Assigned",
    key: "unassigned",
    style: {
      numFmt: format,
    },
  });

  columns.push({
    header: "Total",
    key: "total",
    style: {
      numFmt: numFmt,
    },
  });

  worksheet.columns = columns;

  for (let r in budget.data.rows) {
    let e = budget.data.rows[r];

    if (optionExport.listByMonth) {
      for (let m = 0; m < 13; m++) {
        let currDate = new Date(filter.year + "-" + (m === 12 ? 12 : m + 1) + "-01 GMT");
        while (e.productFocus[0] && e.productFocus[0].endDate && currDate >= e.productFocus[0].endDate) {
          e.productFocus.shift();
        }

        let currFocus = e.productFocus[0];
        if (!currFocus || currFocus.startDate > currDate) {
          currFocus = { segments: [] };
        }

        addLine(worksheet, e, m, optionExport["values"], currFocus);
      }
    } else {
      // get sum year
      addLine(worksheet, e, 13, optionExport["values"], null, filter.year, segments || []);
    }

    // Border line employee
    // if (optionExport.listByMonth)
    //   for (let c = 0; c < columns.length; c++) {
    //     worksheet.getRow(worksheet.rowCount).getCell(c + 1).border = {
    //       bottom: { style: "thin", color: "5E5E5E" },
    //     };
    //   }
  }

  // Apply styles to the header row
  const headerRow = worksheet.getRow(1);
  headerRow.eachCell((cell) => {
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "BCC0BF" },
      bgColor: { argb: "BCC0BF" },
    };
    cell.font = {
      bold: true,
      color: { argb: "000" },
    };
  });

  // Generate Excel file buffer
  const buffer = await workbook.xlsx.writeBuffer();

  // Save the Excel file
  let name = `Export Product Focus ${teamName || "All"} - ${filter.year} - ${optionExport["values"]}.xlsx`;
  saveAs(new Blob([buffer]), name);
}

const addLine = (worksheet, e, month, values, currFocus, year, segments) => {
  let employee = {
    Division: e.employee?.divisionName,
    Department: e.employee?.departmentName || e.departmentName,
    Team: e.employee?.teamName || e.teamName,
    "EE No.": e.employee?.hrCode,
    "First Name": e.employee?.firstName,
    "Last Name": e.employee?.lastName,
    month: month + 1,
  };

  let unassigned = 1;
  if (values === "actuals") {
    unassigned = e.months[month].salaryActual_USD || 0;
  } else if (values === "plan") {
    unassigned = e.months[month].salaryPlan_USD || 0;
  }

  if (currFocus) {
    for (let segment of currFocus.segments) {
      let val = segment.value / 100;
      if (values === "actuals") {
        val = (e.months[month].salaryActual_USD / 100) * segment.value;
      } else if (values === "plan") {
        val = (e.months[month].salaryPlan_USD / 100) * segment.value;
      }

      if (isNumeric(val) && val !== 0) {
        let remove = parseFloat(parseFloat(val).toFixed(2));
        unassigned -= remove;
        unassigned = parseFloat(parseFloat(unassigned).toFixed(2));
        employee[segment.productSegmentId] = val;
      }
    }
  } else {
    for (let m = 0; m < 13; m++) {
      let currDate = new Date(year + "-" + (m === 12 ? 12 : m + 1) + "-01 GMT");
      if (e.productFocus && e.productFocus[0]) {
        while (e.productFocus[0] && e.productFocus[0].endDate && currDate > e.productFocus[0].endDate) {
          e.productFocus.shift();
        }

        if (e.productFocus[0].startDate <= currDate) {
          for (let segment of e.productFocus[0].segments) {
            let val = segment.value / 100 / 13;
            if (values === "actuals") {
              val = (e.months[m].salaryActual_USD / 100) * segment.value;
            } else if (values === "plan") {
              val = (e.months[m].salaryPlan_USD / 100) * segment.value;
            }

            if (isNumeric(val) && val !== 0) {
              let remove = parseFloat(parseFloat(val).toFixed(2));
              unassigned -= remove;
              unassigned = parseFloat(parseFloat(unassigned).toFixed(2));
              if (!employee[segment.productSegmentId]) employee[segment.productSegmentId] = 0;
              employee[segment.productSegmentId] += val;
            }
          }
        }
      }
    }

    if (values === "percentage") {
      unassigned = 1;
      for (let s of segments) {
        let remove = employee[s.productSegmentId] || 0;
        unassigned -= remove;
      }
      unassigned = parseFloat(parseFloat(unassigned).toFixed(2));
    }
  }

  let total = e.months[month]["salaryActual_USD"];
  if (values === "plan") {
    total = e.months[month]["salaryPlan_USD"];
  }

  let row = {
    ...employee,
    total: currencyFormatExcelNum(total),
  };

  if (unassigned && unassigned !== 0) {
    row.unassigned = currencyFormatExcelNum(unassigned);
  }

  worksheet.addRow(row);
};

function isNumeric(value) {
  return !isNaN(parseFloat(value)) && isFinite(value);
}
