import { useState } from "react";
import Modal from "../Modal";
import exportDefault from "./exportsUtils/exportDefault";
import exportMonth from "./exportsUtils/exportMonth";
import exportComparison from "./exportsUtils/exportComparison";
import exportProductFocus from "./exportsUtils/exportProductFocus";

export default function ExportModal({ teamName, filter, budget, visibility, show, close, highlightedMonth }) {
  const [optionExport, setOptionExport] = useState({
    type: "default",
    compare: true,
    showBase: true,
    showBonus: false,
    showOther: false,
    showLoaded: false,
    showTeam: false,
    currency: filter.groupBy ? "USD" : "",
    month: highlightedMonth || 0,
    listByMonth: false,
    values: "actuals",
  });
  const [loading, setLoading] = useState(false);

  const setExportValue = (key, value) => {
    let set = {};
    set[key] = value;
    setOptionExport({ ...optionExport, ...set });
  };

  const exportData = async () => {
    setLoading(true);
    try {
      if (optionExport.type === "default") {
        await exportDefault(filter, teamName, visibility);
      } else if (optionExport.type === "monthly") {
        await exportMonth(filter, teamName, optionExport["month"]);
      } else if (optionExport.type === "comparison") {
        await exportComparison(filter, teamName, optionExport);
      } else if (optionExport.type === "productFocus") {
        await exportProductFocus(filter, teamName, optionExport);
      }
      setLoading(false);
    } catch (e) {
      alert("Error exporting data");
      console.error(e);
      setLoading(false);
    }
  };

  return (
    <>
      {show && (
        <Modal size={"small"} close={() => close(false)}>
          <div className="modal-header">Export Data</div>

          <div className="modal-body">
            <div className="subtitle">Select options</div>

            <div className="label-row">
              <label>Export Type</label>
              <select value={optionExport["type"]} onChange={(e) => setExportValue("type", e.target.value)}>
                <option value={"default"}>Default</option>
                <option value={"monthly"}>Export Month</option>
                <option value={"comparison"}>Export Comparison</option>
                <option value={"productFocus"}>Export Product Focus</option>
              </select>
            </div>

            {optionExport["type"] === "comparison" && (
              <>
                {!filter.groupBy && (
                  <div className="label-row">
                    <label>Currency</label>
                    <select value={optionExport["currency"]} onChange={(e) => setExportValue("currency", e.target.value)}>
                      <option value={""}>Both</option>
                      <option value={"USD"}>Only USD</option>
                      <option value={"Native"}>Only Native Currency</option>
                    </select>
                  </div>
                )}

                {!filter.groupBy && (
                  <div className="label-row vertical-top">
                    <label>Team Total</label>
                    <div>
                      <div className="content-checkbox">
                        <input
                          type="checkbox"
                          checked={optionExport["showTeam"]}
                          onChange={(e) => setExportValue("showTeam", e.target.checked)}
                        ></input>
                        Show
                      </div>
                    </div>
                  </div>
                )}

                <div className="label-row vertical-top">
                  <label>Show columns</label>
                  <div>
                    <div className="content-checkbox">
                      <input
                        type="checkbox"
                        checked={optionExport["showBase"]}
                        onChange={(e) => setExportValue("showBase", e.target.checked)}
                      ></input>
                      Base
                    </div>
                    <div className="content-checkbox">
                      <input
                        type="checkbox"
                        checked={optionExport["showBonus"]}
                        onChange={(e) => setExportValue("showBonus", e.target.checked)}
                      ></input>
                      Bonus
                    </div>
                    <div className="content-checkbox">
                      <input
                        type="checkbox"
                        checked={optionExport["showOther"]}
                        onChange={(e) => setExportValue("showOther", e.target.checked)}
                      ></input>
                      Other
                    </div>
                    <div className="content-checkbox">
                      <input
                        type="checkbox"
                        checked={optionExport["showLoaded"]}
                        onChange={(e) => setExportValue("showLoaded", e.target.checked)}
                      ></input>
                      Loaded
                    </div>
                  </div>
                </div>
                <div className="label-row vertical-top">
                  <label>Next year</label>
                  <div>
                    <div className="content-checkbox">
                      <input
                        type="checkbox"
                        checked={optionExport["compare"]}
                        onChange={(e) => setExportValue("compare", e.target.checked)}
                      ></input>
                      Enable
                    </div>
                  </div>
                </div>
              </>
            )}

            {optionExport["type"] === "monthly" && (
              <>
                <div className="label-row">
                  <label>Month</label>
                  <select value={optionExport["month"]} onChange={(e) => setExportValue("month", e.target.value)}>
                    <option value="0">January</option>
                    <option value="1">February</option>
                    <option value="2">March</option>
                    <option value="3">April</option>
                    <option value="4">May</option>
                    <option value="5">June</option>
                    <option value="6">July</option>
                    <option value="7">August</option>
                    <option value="8">September</option>
                    <option value="9">October</option>
                    <option value="10">November</option>
                    <option value="11">December</option>
                    <option value="12">13th</option>
                  </select>
                </div>
              </>
            )}

            {optionExport["type"] === "productFocus" && (
              <>
                <div className="label-row vertical-top">
                  <label>List By month</label>
                  <div className="content-checkbox">
                    <input
                      type="checkbox"
                      checked={optionExport["listByMonth"]}
                      onChange={(e) => setExportValue("listByMonth", e.target.checked)}
                    ></input>
                    Enable
                  </div>
                </div>
                <div className="label-row">
                  <label>Values</label>
                  <select value={optionExport["values"]} onChange={(e) => setExportValue("values", e.target.value)}>
                    <option value="actual">Actuals</option>
                    <option value="plan">Planned</option>
                    <option value="percentage">Percentage</option>
                  </select>
                </div>
              </>
            )}
          </div>

          <div className="modal-footer">
            <div className="btn-bar-left"></div>
            <div className="btn-bar-right">
              <button disabled={loading} className="green" onClick={() => exportData()}>
                {loading ? "Exporting..." : "Export"}
              </button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}
