import Papa from "papaparse";
import { currencyFormatExcel } from "../../../services/utils";
import api from "../../../services/api";
import moment from "moment";

export default async function exportData(filter, teamName, highlightedMonth) {
  console.log(highlightedMonth);
  highlightedMonth = parseInt(highlightedMonth);
  let getDataApi = api.getBudgetData;

  if (filter.groupBy) {
    getDataApi = api.getBudgetGroupBy;
  }

  let budget = await getDataApi(filter, {
    curPage: 1,
    pageSize: 999999,
  });

  let data = [];

  for (let e of budget.data.rows) {
    let head = {};
    if (filter.groupBy) {
      if (filter.groupBy === "Team") {
        head = { Team: e.teamName };
      } else if (filter.groupBy === "Department") {
        head = { Department: e.departmentName };
      }
    } else {
      console.log(e.months);
      head = {
        "HR Code": e.employee.hrCode,
        "First Name": e.employee.firstName,
        "Last Name": e.employee.lastName,
        Department: e.months[highlightedMonth].actualRole.departmentName,
        Division: e.months[highlightedMonth].actualRole.divisionName,
        Location: e.months[highlightedMonth].actualRole.location,
        "Payroll Source": e.months[highlightedMonth].actualRole.payrollSource,
        "TW Office": e.months[highlightedMonth].actualRole.twOffice,
        Team: e.months[highlightedMonth].actualRole.teamName,
        Position: e.months[highlightedMonth].actualRole.position,
        Currency: e.months[highlightedMonth].actualRole.currencyCode || "USD",
      };
    }

    data.push({
      ...head,

      "Base Plan": currencyFormatExcel(e.months[highlightedMonth].salaryPlan_USD),
      "Bonus Plan": currencyFormatExcel(e.months[highlightedMonth].bonusPlan_USD),
      "Other Plan": currencyFormatExcel(e.months[highlightedMonth].otherPlan_USD),
      "Loaded Plan": currencyFormatExcel(e.months[highlightedMonth].totalPlan_USD),
      "Base Actual": currencyFormatExcel(e.months[highlightedMonth].salaryActual_USD),
      "Bonus Actual": currencyFormatExcel(e.months[highlightedMonth].bonusActual_USD),
      "Other Actual": currencyFormatExcel(e.months[highlightedMonth].otherActual_USD),
      "Loaded Actual": currencyFormatExcel(e.months[highlightedMonth].totalActual_USD),
    });
  }

  let total = getTotal(budget.data.rows);
  data.push({
    "First Name": "TOTAL",
    "Base Plan": currencyFormatExcel(total[highlightedMonth].salaryPlan_USD),
    "Bonus Plan": currencyFormatExcel(total[highlightedMonth].bonusPlan_USD),
    "Other Plan": currencyFormatExcel(total[highlightedMonth].otherPlan_USD),
    "Loaded Plan": currencyFormatExcel(total[highlightedMonth].totalPlan_USD),
    "Base Actual": currencyFormatExcel(total[highlightedMonth].salaryActual_USD),
    "Bonus Actual": currencyFormatExcel(total[highlightedMonth].bonusActual_USD),
    "Other Actual": currencyFormatExcel(total[highlightedMonth].otherActual_USD),
    "Loaded Actual": currencyFormatExcel(total[highlightedMonth].totalActual_USD),
  });

  var csv = Papa.unparse(data);
  downloadCsv(
    csv,
    `Export ${teamName || "All"} - ${highlightedMonth !== 12 ? moment().month(highlightedMonth).format("MMM") : "13th"} ${
      filter.year
    } - USD`
  );
}

const getTotal = (budget) => {
  let total = [];
  for (let employee of budget) {
    for (let month in employee.months) {
      if (employee.months[month]) {
        if (!total[month]) {
          total[month] = {
            bonusActual: 0,
            bonusActual_USD: 0,
            bonusPlan: 0,
            bonusPlan_USD: 0,
            currencyCode: 0,
            otherActual: 0,
            otherActualMap: {},
            otherActualMap_USD: {},
            otherActual_USD: 0,
            otherPlan: 0,
            otherPlan_USD: 0,
            salaryActual: 0,
            salaryActual_USD: 0,
            salaryPlan: 0,
            salaryPlan_USD: 0,
            totalActual: 0,
            totalActual_USD: 0,
            totalPlan: 0,
            totalPlan_USD: 0,
          };
        }

        if (month === 13) {
          total[month].bonusActual += employee.months[month].bonusActual;
          total[month].bonusPlan += employee.months[month].bonusPlan;
          total[month].otherActual += employee.months[month].otherActual;
          total[month].otherPlan += employee.months[month].otherPlan;
          total[month].salaryActual += employee.months[month].salaryActual;
          total[month].salaryPlan += employee.months[month].salaryPlan;
          total[month].totalActual += employee.months[month].totalActual;
          total[month].totalPlan += employee.months[month].totalPlan;
        } else {
          total[month].bonusActual_USD += employee.months[month].bonusActual_USD || 0;
          total[month].bonusPlan_USD += employee.months[month].bonusPlan_USD || 0;
          total[month].otherActual_USD += employee.months[month].otherActual_USD || 0;
          total[month].otherPlan_USD += employee.months[month].otherPlan_USD || 0;
          total[month].salaryActual_USD += employee.months[month].salaryActual_USD || 0;
          total[month].salaryPlan_USD += employee.months[month].salaryPlan_USD || 0;
          total[month].totalActual_USD += employee.months[month].totalActual_USD || 0;
          total[month].totalPlan_USD += employee.months[month].totalPlan_USD || 0;

          total[month].bonusActual = total[month].bonusActual_USD;
          total[month].bonusPlan = total[month].bonusPlan_USD;
          total[month].currencyCode = total[month].currencyCode_USD;
          total[month].otherActual = total[month].otherActual_USD;
          total[month].otherPlan = total[month].otherPlan_USD;
          total[month].salaryActual = total[month].salaryActual_USD;
          total[month].salaryPlan = total[month].salaryPlan_USD;
          total[month].totalActual = total[month].totalActual_USD;
          total[month].totalPlan = total[month].totalPlan_USD;
        }
      }
    }
  }

  if (!total[13]) total[13] = {};
  return total;
};

const downloadCsv = (data, fileName) => {
  const csvData = new Blob([data], { type: "text/csv" });
  const csvURL = URL.createObjectURL(csvData);
  const link = document.createElement("a");
  link.href = csvURL;
  link.download = `${fileName}.csv`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
